<template>
  <main class="content">
    <div class="inner content-grid">
      <div class="left">
        <page-heading heading="Contact Customer Services" icon="envelope-customer-services.png" :para="success_message
          ? 'Thank you, your request is now being actioned.'
          : 'Please enter the details using the form below.'
          "></page-heading>
        <template v-if="!success_message">
          <info-panel content="Fill out as much information as you can to help process your request."></info-panel>
          <div class="form">
            <template v-if="$store.state.user_type == 'hq-user'">
              <!-- <div class="form-input-holder">
                <input aria-labelledby="lblAccountNumber" class="form-input" type="search" list="dlAccounts"
                  v-model="account_number" @input="addAccount" />
                <datalist id="dlAccounts">
                  <option v-for="account in availableAccounts" :value="account.formattedAccountNumber">{{
          account.company + (account.customer_ref ? ` - ${account.customer_ref}` : '') }}</option>
                </datalist>

                <ul>
                  <li class="form-input selected-account" v-for="(account, index) in selectedAccounts" :key="index">
                    <div class="account-info">
                      {{ account.account_number }} <br />
                      {{ `${account.company}${account.customer_ref ? ` - ${account.customer_ref}` : ''}` }}
                    </div>
                    <a href @click.prevent="removeAccount(account)" aria-label="Remove from list" class="remove-icon">
                      <font-awesome-icon icon="times" aria-hidden="true" role="presentation" />
                    </a>
                  </li>
                </ul>
              </div> -->

              <div class="form-row">
                <div class="form-label" id="lblAccounts">Account(s):</div>

                <div class="checkbox-container">
                  <input id="all-sites" v-model="all_sites" class="chk" type="checkbox" />
                  <label for="all-sites">All sites</label>
                </div>
              </div>

              <template v-if="!all_sites">

                <div class="form-row">
                  <div class="form-label">&nbsp;</div>

                  <div class="form-input-holder">
                    <input aria-labelledby="lblAccounts" type="text" class="form-input" v-model.trim="site_search"
                      placeholder="Search by site number or name" @input="page_number = 1" />
                  </div>
                </div>

                <table class="reports-accounts">
                  <thead>
                    <tr>
                      <td>Site Number</td>
                      <td>Site Name</td>
                      <td>Customer Ref</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="account in pagedAccounts" :key="account.account_number">
                      <td>{{ account.formattedAccountNumber }}</td>
                      <td>{{ account.company }}</td>
                      <td>{{ account.customer_ref }}</td>

                      <td>
                        <input type="checkbox" v-model="account_numbers" :value="account.account_number" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="reports-accounts-nav">
                  <a href="" v-if="page_number > 1" class="prev" @click.prevent="page_number--">&lt; Prev</a>
                  <a href="" v-if="page_number < numberOfPages" class="next" @click.prevent="page_number++">Next
                    &gt;</a>
                </div>
              </template>
            </template>

            <div class="form-row" v-if="$store.state.user_type != 'hq-user'">
              <div class="form-label">Account number:</div>
              <div class="form-input-holder padded">{{ customer.account_number }}</div>
            </div>

            <div class="form-row">
              <div id="lblContactName" class="form-label">Contact name:</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblContactName" type="text" class="form-input" v-model="contact_name">
              </div>
            </div>
            <div class="form-row">
              <div id="lblEmail" class="form-label">Email:</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblEmail" type="text" class="form-input" v-model="email">
              </div>
            </div>
            <div class="form-row">
              <div id="lblTelephoneNumber" class="form-label">Telephone number:</div>
              <div class="form-input-holder">
                <input aria-labelledby="lblTelephoneNumber" type="text" class="form-input" v-model="telephone_number">
              </div>
            </div>
            <div class="form-row">
              <div id="lblDetailsOfIssue" class="form-label">Details of issue:</div>
              <div class="form-input-holder">
                <select aria-labelledby="lblDetailsOfIssue" class="form-input" v-model="issue_details">
                  <optgroup label="General">
                    <option value="consumables">Consumables</option>
                    <option value="cash_coin_orders">Cash &amp; Coin Orders</option>
                    <option value="Service-related issue">Service-related issue</option>
                    <option value="Reporting">Reporting</option>
                    <option value="Invoicing">Invoicing</option>
                    <option value="Discrepancies">Discrepancies</option>
                    <option value="Safepoint">Safepoint</option>
                    <option value="Driver behaviour">Driver behaviour</option>
                    <option value="Complaint">Complaint</option>
                  </optgroup>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <template v-if="issue_details == 'cash_coin_orders'">
              <div class="form-row">
                <div class="form-label">Delivery date:</div>
                <div class="form-input-number">
                  <flat-pickr v-model="order_delivery_date" ref="dateWrapperElement" :config="date_config"
                    class="txt full date"></flat-pickr>
                </div>
              </div>
              <div class="form-row">
                <div id="lblNote50" class="form-label">£50:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblNote50" type="number" class="form-input" v-model.number="note_50">
                </div>
              </div>
              <div class="form-row">
                <div id="lblNote20" class="form-label">£20:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblNote20" type="number" class="form-input" v-model.number="note_20">
                </div>
              </div>
              <div class="form-row">
                <div id="lblNote10" class="form-label">£10:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblNote10" type="number" class="form-input" v-model.number="note_10">
                </div>
              </div>
              <div class="form-row">
                <div id="lblNote5" class="form-label">£5:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblNote5" type="number" class="form-input" v-model.number="note_5">
                </div>
              </div>
              <div class="form-row">
                <div id="lblCoin200" class="form-label">£2:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblCoin200" type="number" class="form-input" v-model.number="coin_200">
                </div>
              </div>
              <div class="form-row">
                <div id="lblCoin100" class="form-label">£1:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblCoin100" type="number" class="form-input" v-model.number="coin_100">
                </div>
              </div>
              <div class="form-row">
                <div id="lblCoin50" class="form-label">50p:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblCoin50" type="number" class="form-input" v-model.number="coin_50">
                </div>
              </div>
              <div class="form-row">
                <div id="lblCoin20" class="form-label">20p:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblCoin20" type="number" class="form-input" v-model.number="coin_20">
                </div>
              </div>
              <div class="form-row">
                <div id="lblCoin10" class="form-label">10p:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblCoin10" type="number" class="form-input" v-model.number="coin_10">
                </div>
              </div>
              <div class="form-row">
                <div id="lblCoin5" class="form-label">5p:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblCoin5" type="number" class="form-input" v-model.number="coin_5">
                </div>
              </div>
              <div class="form-row">
                <div id="lblCoin2" class="form-label">2p:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblCoin2" type="number" class="form-input" v-model.number="coin_2">
                </div>
              </div>
              <div class="form-row">
                <div id="lblCoin1" class="form-label">1p:</div>
                <div class="form-input-number">
                  <input aria-labelledby="lblCoin1" type="number" class="form-input" v-model.number="coin_1">
                </div>
              </div>
              <div class="form-row">
                <div id="lblStandingOrder" class="form-label">Can we help by setting this up as a standing order?</div>
                <div class="form-input-number">
                  <select aria-labelledby="lblStandingOrder" class="form-input" v-model="standing_order">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <template v-if="standing_order == 'Yes'">
                <div class="form-row">
                  <div id="lblStandingOrderFrequency" class="form-label">Frequency:</div>
                  <div class="form-input-number">
                    <select aria-labelledby="lblStandingOrderFrequency" class="form-input"
                      v-model="standing_order_frequency">
                      <option value="Weekly">Weekly</option>
                      <option value="Fortnightly">Fortnightly</option>
                      <option value="Monthly">Monthly</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-label">End date:</div>
                  <div class="form-input-number">
                    <flat-pickr v-model="standing_order_end_date" ref="dateWrapperElement" :config="date_config"
                      class="txt full date"></flat-pickr>
                  </div>
                </div>
              </template>
            </template>

            <template v-if="issue_details == 'consumables'">
              <div class="form-row">
                <div id="lblTypeOfRequest" class="form-label">Type of request:</div>
                <div class="form-input-holder">
                  <select aria-labelledby="lblTypeOfRequest" class="form-input" v-model="request_type">
                    <option value="Order">Order</option>
                    <option value="Query">Query</option>
                  </select>
                </div>
              </div>

              <div v-if="request_type == 'Query'">
                <div class="form-input-holder">
                  <textarea aria-label="Details of query" v-model="query_details" class="form-input form-textarea"
                    placeholder="Details of query..."></textarea>
                </div>
              </div>

              <template v-if="request_type == 'Order'">
                <div class="form-row">
                  <div class="form-label">Delivery date:</div>
                  <div class="form-input-number">
                    <flat-pickr v-model="consumable_delivery_date" ref="dateWrapperElement" :config="date_config"
                      class="txt full date"></flat-pickr>
                  </div>
                </div>
                <div class="form-row">
                  <div id="lblBagType" class="form-label">Bag type:</div>
                  <div class="form-input-holder">
                    <select aria-labelledby="lblBagType" class="form-input" v-model="bag_type">
                      <option v-for="consumable in consumables" :value="consumable.name">{{ consumable.name }}</option>
                    </select>
                  </div>
                </div>
              </template>

            </template>
            <template v-if="issue_details !== 'consumables' && issue_details !== 'cash_coin_orders'">
              <div class="form-row">
                <div id="lblWhatHelpDoYouNeed" class="form-label">If you already have a case number, please enter it
                  here
                </div>
                <div class="form-input-holder">
                  <input aria-labelledby="lblWhatHelpDoYouNeed" type="text" class="form-input"
                    v-model="what_help_do_you_need">
                </div>
              </div>
              <div>
                <div class="form-input-holder">
                  <textarea aria-label="Explain what has happened here" v-model="explanation"
                    class="form-input form-textarea" placeholder="Explain what has happened here..."></textarea>
                </div>
              </div>

              <div class="form-row">
                <div class="form-label">File(s):</div>
                <div class="form-input-holder">
                  <div class="note">
                    NOTE: Please ensure your files are either an image, or are one of the following supported types:
                    Excel
                    (.xls,
                    .xlsx), Word documents (.doc, .docx), Outlook messages (.msg), or PDF (.pdf).
                    <br />You can attach up to 3 files with a
                    limit of 5mb per file.
                  </div>
                  <div>
                    <input v-if="refresh" type="file"
                      accept="image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-outlook,application/pdf"
                      @change="upload($event, 'file_1')" class="form-input form-file" id="file-input-1" />

                    <div class="uploaded" v-if="total_files >= 2">
                      {{ file_1_name }}
                      <a href @click.prevent="deleteFile(1)" class="delete">x</a>
                    </div>
                  </div>
                  <div>
                    <input v-if="refresh" type="file"
                      accept="image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-outlook,application/pdf"
                      @change="upload($event, 'file_2')" class="form-input form-file" id="file-input-2" />
                    <div class="uploaded" v-if="total_files >= 3">
                      {{ file_2_name }}
                      <a href @click.prevent="deleteFile(2)" class="delete">x</a>
                    </div>
                  </div>
                  <div>
                    <input v-if="refresh" type="file"
                      accept="image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-outlook,application/pdf"
                      @change="upload($event, 'file_3')" class="form-input form-file" id="file-input-3" />
                    <div class="uploaded" v-if="total_files >= 4">
                      {{ file_3_name }}
                      <a href @click.prevent="deleteFile(3)" class="delete">x</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div></div>
                <div class="form-input-holder">
                  <button v-if="total_files < 4" class="upload" @click="triggerUploader()">
                    {{ total_files > 1 ? "Add another" : "Click to attach" }}
                    <img src="/images/icons/upload.png" alt="Upload" />
                  </button>
                </div>
              </div>
            </template>
          </div>

          <error-panel v-if="error_message" :content="error_message"></error-panel>

          <div class="actions">
            <button :disabled="submit_loading" @click="submit">
              <template v-if="submit_loading">
                <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
              </template>
              <template v-else> Submit <img src="/images/icons/button-icon-submit.png" alt="submit" /> </template>
            </button>
          </div>
        </template>
        <template v-else>
          <success-panel :content="success_message"></success-panel>
          <p class="back"><router-link to="/home">&lt; Back to Dashboard...</router-link></p>
        </template>
      </div>
      <right-column></right-column>
    </div>
  </main>
</template>

<script>
import api from "@/services/api"
import { formatAccountNumber, reverseFormatAccountNumber } from "@/util/vars"

var minDate = new Date()

export default {
  data() {
    return {
      all_sites: false,
      site_search: "",
      account_numbers: [],
      page_size: 5,
      page_number: 1,
      account_number: "",
      contact_name: "",
      email: "",
      telephone_number: "",
      issue_details: "",
      request_type: "",
      query_details: "",
      consumable_delivery_date: null,
      order_delivery_date: null,
      bag_type: "",
      note_50: "",
      note_20: "",
      note_10: "",
      note_5: "",
      coin_200: "",
      coin_100: "",
      coin_50: "",
      coin_20: "",
      coin_10: "",
      coin_5: "",
      coin_2: "",
      coin_1: "",
      standing_order: "",
      standing_order_frequency: "",
      standing_order_end_date: null,
      what_help_do_you_need: "",
      explanation: "",
      accounts: [],
      selectedAccounts: [],

      refresh: true,
      total_files: 1,
      file_1_data: "",
      file_2_data: "",
      file_3_data: "",
      file_1_name: "",
      file_2_name: "",
      file_3_name: "",

      submit_loading: false,

      error_message: "",
      success_message: "",

      consumables: [],

      date_config: {
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ["S", "M", "T", "W", "T", "F", "S"]
          }
        },
        minDate,
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disableMobile: "true"
      },
    }
  },
  created() {
    var contact_name_prop = this.$store.state.myDetails.filter(p => p.property == "name")
    if (contact_name_prop.length) this.contact_name = contact_name_prop[0]._content

    var email_prop = this.$store.state.myDetails.filter(p => p.property == "email")
    if (email_prop.length) this.email = email_prop[0]._content

    var telephone_number_prop = this.$store.state.myDetails.filter(p => p.property == "phone_number")
    if (!telephone_number_prop.length) telephone_number_prop = this.$store.state.myDetails.filter(p => p.property == "custom:phone")
    if (telephone_number_prop.length) this.telephone_number = telephone_number_prop[0]._content

    if (this.$store.state.user_type == "hq-user") {
      api.request("get", "account?all=true").then(res => {
        res.forEach(a => (a.formattedAccountNumber = this.formatAccNo(a.account_number)))

        this.accounts = res
      })
    }

    api
      .request("get", "consumable?type=all")
      .then(res => {
        this.consumables = res.consumables
      })
  },
  computed: {
    hq_user() {
      return this.$store.state.hq_user
    },
    customer() {
      return this.$store.state.customer
    },
    availableAccounts() {
      return this.accounts.filter(account => !this.selectedAccounts.includes(account));
    },
    numberOfPages: function () {
      return Math.ceil(this.filteredAccounts.length / this.page_size)
    },
    pagedAccounts() {
      return this.filteredAccounts.slice((this.page_number - 1) * this.page_size, this.page_number * this.page_size)
    },
    filteredAccounts() {
      if (!this.site_search) return this.accounts

      var site_search = this.site_search.toLowerCase()

      return this.accounts.filter(
        a =>
          (a.account_number && a.account_number.includes(site_search)) ||
          (a.formattedAccountNumber && a.formattedAccountNumber.toLowerCase().includes(site_search)) ||
          (a.company && a.company.toLowerCase().includes(site_search)) ||
          (a.customer_ref && a.customer_ref.toLowerCase().includes(site_search))
      )
    }
  },
  methods: {
    triggerUploader() {
      document.getElementById("file-input-" + this.total_files).click()
    },
    deleteFile(i) {
      this.refresh = false
      this.$nextTick().then(() => (this.refresh = true))

      this.total_files--

      if (i == 1) {
        this["file_1_data"] = this["file_2_data"]
        this["file_1_name"] = this["file_2_name"]
      }
      if (i <= 2) {
        this["file_2_data"] = this["file_3_data"]
        this["file_2_name"] = this["file_3_name"]
      }
      this["file_3_data"] = ""
      this["file_3_name"] = ""
    },
    upload: function (e, prop) {
      this.success_message = ""
      this.error_message = ""

      var files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      if (files[0].size > 5242880) return (this.error_message = "Please ensure all files are under 5mb")

      var reader = new FileReader()
      reader.addEventListener(
        "load",
        () => {
          const supportedFileTypes = [
            "data:image/",
            "data:application/vnd.ms-excel",
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "data:application/msword",
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "data:application/vnd.ms-outlook",
            "data:application/pdf"
          ];

          if (!supportedFileTypes.some(type => reader.result.startsWith(type))) {
            return (this.error_message = "Please select a valid file. Supported types: JPEG images, Excel (.xls, .xlsx), Word documents (.doc, .docx), Outlook messages (.msg), or PDF (.pdf).");
          }

          this[prop + "_data"] = reader.result
          this[prop + "_name"] = files[0].name
          this.total_files++
        },
        false
      )

      reader.readAsDataURL(files[0])
    },
    removeAccount(account) {
      const index = this.selectedAccounts.indexOf(account);
      if (index !== -1) {
        this.selectedAccounts.splice(index, 1);
      }
    },
    addAccount() {
      if (this.account_number && this.account_number.length === 11 && this.accounts.some(account => account.formattedAccountNumber === this.account_number)) {
        const account = this.accounts.find(account => account.formattedAccountNumber === this.account_number)
        if (!this.selectedAccounts.includes(account)) {
          this.selectedAccounts.push(account);
        }
        this.account_number = '';
      }
    },
    formatAccNo(acc_no) {
      return formatAccountNumber(acc_no)
    },
    reverseFormatAccNo(acc_no) {
      return reverseFormatAccountNumber(acc_no)
    },
    submit() {
      this.submit_loading = true
      this.error_message = ""
      this.success_message = ""

      if (this.$store.state.user_type == 'hq-user' && !this.all_sites && !this.account_numbers.length) {
        this.error_message = "Please select account(s)"
        this.submit_loading = false
        return
      }
      if (!this.issue_details) {
        this.error_message = "Please enter the details of the fault"
        this.submit_loading = false
        return
      }

      var total = 0
      if (this.issue_details == "cash_coin_orders") {
        if (!this.order_delivery_date) {
          this.error_message = "Please select a delivery date"
          this.submit_loading = false
          return
        }

        if (this.note_50) total += this.note_50
        if (this.note_20) total += this.note_20
        if (this.note_10) total += this.note_10
        if (this.note_5) total += this.note_5
        if (this.coin_200) total += this.coin_200
        if (this.coin_100) total += this.coin_100
        if (this.coin_50) total += this.coin_50
        if (this.coin_20) total += this.coin_20
        if (this.coin_10) total += this.coin_10
        if (this.coin_5) total += this.coin_5
        if (this.coin_2) total += this.coin_2
        if (this.coin_1) total += this.coin_1

        if (!total) {
          this.error_message = "Please enter the amount of cash and coin you would like to order"
          this.submit_loading = false
          return
        }

        if (!this.standing_order) {
          this.error_message = "Please select if you would like to set up a standing order"
          this.submit_loading = false
          return
        }
        if (this.standing_order == "Yes" && !this.standing_order_frequency) {
          this.error_message = "Please select a frequency for the standing order"
          this.submit_loading = false
          return
        }
        if (this.standing_order == "Yes" && !this.standing_order_end_date) {
          this.error_message = "Please select an end date for the standing order"
          this.submit_loading = false
          return
        }
      } else if (this.issue_details == "consumables") {
        if (!this.request_type) {
          this.error_message = "Please select a type of request"
          this.submit_loading = false
          return
        }

        if (this.request_type == "Query" && !this.query_details) {
          this.error_message = "Please enter the details of the query"
          this.submit_loading = false
          return
        }

        if (this.request_type == "Order" && !this.consumable_delivery_date) {
          this.error_message = "Please select a delivery date"
          this.submit_loading = false
          return
        }
        if (this.request_type == "Order" && !this.bag_type) {
          this.error_message = "Please select the bag type"
          this.submit_loading = false
          return
        }
      } else {
        if (!this.contact_name) {
          this.error_message = "Please enter a contact name"
          this.submit_loading = false
          return
        }
        if (!this.email) {
          this.error_message = "Please enter your email address"
          this.submit_loading = false
          return
        }
        if (!this.telephone_number) {
          this.error_message = "Please enter your telephone number"
          this.submit_loading = false
          return
        }
        if (!this.explanation) {
          this.error_message = "Please explain what has happened here"
          this.submit_loading = false
          return
        }
      }

      var data = {
        contact_name: this.contact_name,
        email: this.email,
        telephone_number: this.telephone_number,
        issue_details: this.issue_details,
        what_help_do_you_need: this.what_help_do_you_need,
        explanation: this.explanation,
        file_1_data: this.file_1_data,
        file_2_data: this.file_2_data,
        file_3_data: this.file_3_data,
        file_1_name: this.file_1_name,
        file_2_name: this.file_2_name,
        file_3_name: this.file_3_name,
        issue_details: this.issue_details,
        request_type: this.request_type,
        query_details: this.query_details,
        consumable_delivery_date: this.consumable_delivery_date ? this.consumable_delivery_date.split("-").reverse().join("/") : null,
        bag_type: this.bag_type,
        order_delivery_date: this.order_delivery_date ? this.order_delivery_date.split("-").reverse().join("/") : null,
        bag_type: this.bag_type,
        note_50: this.note_50,
        note_20: this.note_20,
        note_10: this.note_10,
        note_5: this.note_5,
        coin_200: this.coin_200,
        coin_100: this.coin_100,
        coin_50: this.coin_50,
        coin_20: this.coin_20,
        coin_10: this.coin_10,
        coin_5: this.coin_5,
        coin_2: this.coin_2,
        coin_1: this.coin_1,
        standing_order: this.standing_order,
        standing_order_frequency: this.standing_order_frequency,
        standing_order_end_date: this.standing_order_end_date ? this.standing_order_end_date.split("-").reverse().join("/") : null,
        total
      }

      if (this.$store.state.user_type == 'hq-user' && !this.all_sites) {
        const accountData = []
        this.account_numbers.forEach(an => {
          const account = this.accounts.find(a => a.account_number === an)
          accountData.push({
            "Site Number": this.reverseFormatAccNo(account.account_number),
            "Site Name": account.company
          })
        })

        data.account_numbers = accountData
      }

      if (this.$store.state.user_type == 'hq-user') {
        data.accountNumber = this.hq_user.prefix
        data.accountName = this.hq_user.name
      } else {
        data.accountNumber = this.customer.account_number
        data.accountName = this.customer.company
      }

      api
        .request("post", "complaints", data)
        .then(() => {
          this.success_message = "Your request has successfully been submitted."
        })
        .catch(() => {
          this.error_message = "There was an error, please try again"
        })
        .finally(() => {
          this.submit_loading = false
        })
    }
  }
}
</script>

<style scoped>
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;

}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

li:hover {
  border: 1px solid #29383f;
}

.selected-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-icon {
  margin-left: auto;
  padding: 0.5rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 5rem;
  margin-bottom: 2rem;
}

.form-input {
  width: 100%;
}

.form-input-number {
  width: 50%;
}

.form-label {
  padding: 1rem 0;
}

.form-input::placeholder {
  color: #ccc;
}

.form-textarea {
  height: 15rem;
}

.radio {
  display: inline-block;
  margin: 1.5rem 0;
  margin-right: 3rem;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 2.8rem;
  cursor: pointer;
  line-height: 2rem;
  display: inline-block;
  font-size: 2rem;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background: #ca0028;
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:focus-visible+label:before {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.note {
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  opacity: 0.7;
}

.btn-another,
.upload {
  background: #ca0028;
  color: white;
  border-radius: 3rem;
  border: 0;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  cursor: pointer;
  margin-bottom: 2rem;
}

.upload img {
  height: 1.8rem;
  position: relative;
  bottom: -0.2rem;
  margin-left: 0.5rem;
}

.form-file {
  display: none;
}

.form-right {
  text-align: right;
}

.delete {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background: #5f6062;
  color: white;
  text-align: center;
  line-height: 1.5rem;
  position: relative;
  top: -0.25rem;
  margin-left: 1rem;
}

.delete:hover {
  background: #ca0028;
}

.uploaded {
  margin: 0.5rem 0;
}

.reports-accounts {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}

.reports-accounts td {
  padding: 1rem 2rem;
  border-top: 1px solid #eee;
}

.reports-accounts thead td {
  background: #eee;
  font-weight: bold;
}

.reports-accounts tr:nth-child(even) td {
  background: #f8f8f8;
}

.reports-accounts input {
  cursor: pointer;
  transform: scale(1.3);
}

.reports-accounts-nav {
  margin-bottom: 3rem;
  text-align: right;
}

.reports-accounts-nav a {
  background: #dc002e;
  display: inline-block;
  border-radius: 0.4rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  margin-left: 1rem;
  color: #fff;
}

.reports-accounts-nav a:hover {
  background: #c4002a;
}

.reports-accounts-selected {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.reports-accounts-selected a {
  background: #eee;
  display: inline-block;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: #333;
}

.reports-accounts-selected a:hover {
  background: #dc002e;
  color: white;
}

.reports-accounts-selected a span {
  font-weight: bold;
  margin-right: 0.5rem;
}

@media screen and (max-width: 600px) {
  .actions button {
    font-size: 1.8rem;
  }

  .actions button img {
    height: 1.5rem;
    margin-bottom: 0;
  }

  .form-row {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
</style>
